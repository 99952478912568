import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';

import * as Api from "../../../Apis"
import Images from '../../common/Index';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUpRightFromSquare, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Accordion, Badge, ListGroup, Modal } from 'react-bootstrap';
import _ from "underscore"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2'
import states from "../../common/states";
import CurrencyFormat from 'react-currency-format';
import jsPDF from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import DownloadInvoice from './DownloadInvoice';
import { addDays } from "date-fns";
import { useMediaQuery } from '@uidotdev/usehooks';


const storeType = [
    { value: "beauty", label: "Beauty" },
    { value: "clothing", label: "Clothing" },
    { value: "electronics", label: "Electronics" },
    { value: "furniture", label: "Furniture" },
    { value: "handcrafts", label: "Handcrafts" },
    { value: "jewellery", label: "Jewellery" },
    { value: "painting", label: "Painting" },
    { value: "photography", label: "Photography" },
    { value: "restaurants", label: "Restaurants" },
    { value: "groceries", label: "Groceries" },
    { value: "other_food_drink", label: "Other food and drink" },
    { value: "sports", label: "Sports" },
    { value: "toys", label: "Toys" },
    { value: "services", label: "Services" },
    { value: "virtual_services", label: "Virtual services" },
    { value: "other", label: "Other" },
    { value: "do_not_know", label: "I haven’t decided yet" },
]

export default function UserDetail(props) {
    const urlParam = useParams()
    const searchParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const [userData, setuserData] = useState([])
    const [planData, setplanData] = useState([])
    const [manualSub, setManualSub] = useState(false)
    const [showDatepicker, setShowDatepicker] = useState(false)
    const [showDatepicker2, setShowDatepicker2] = useState(false)
    const [date, setDate] = useState("")
    const [ExpDate, setExpDate] = useState("")

    const limit = process.env.REACT_APP_LIMIT || 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        search: "",
    })
    const [totalCount, setTotalCount] = useState(0)
    const [value, setValue] = useState({})
    const [phone, setPhone] = useState()

    const [modalSize, setmodalSize] = useState("md")
    const [modalType, setmodalType] = useState("")
    const [modalData, setmodalData] = useState({})
    const [tab, settab] = useState([
        { name: "Pending", value: "pending", active: true },
        { name: "Completed", value: "completed", active: false },
    ])
    const [subscriptionId, setsubscriptionId] = useState("")
    const [errors, setError] = useState(false)
    const [count, setCount] = useState(1)

    const [show, setshow] = useState(false)
    const handleClose = () => {
        setmodalData({})
        setshow(false)
        setmodalType("")
        setmodalSize("md")
        setplanData([])
        setsubscriptionId("")
        setError(false)
        setManualSub(false)
        setValue({})
        setShowDatepicker(false)
        setShowDatepicker2(false)
        setExpDate("")
    }

    const logout = () => {
        localStorage.removeItem("admin_token")
        navigate("/admin/login")
    }

    const handleNumberChange = (val, data) => {
        setValue({ ...value, phone_number: `+${data.dialCode} ${val.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` })
    };

    const getData = (id, param, modal) => {
        Api.GetApiAdmin(`administrative/list${id}${param}`).then(res => {
            console.log("detail res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                let resData = []
                res.data.data?.rows.length > 0 && res.data.data?.rows.map((data) => {
                    let plan = ""
                    let planDetail = {}
                    data.StoreSubscriptions.length > 0 && data.StoreSubscriptions.map((opt) => {
                        if (!opt?.planName) {
                            if (opt?.plan_id == process.env.REACT_APP_STANDARD_PLAN) {
                                plan = "Standard"
                            } else {
                                plan = "Professional"
                            }
                        } else {
                            plan = opt?.planName
                        }
                        planDetail = {
                            status: opt?.status
                        }
                    })
                    resData.push({ ...data, plan: plan, planDetail: planDetail })
                })
                setuserData(resData)
                if (modal == "close") {
                    handleClose()
                }
                setTotalCount(res.data.data?.count || 0)
                let newCount = Math.ceil(parseInt(res.data.data?.count) / limit)

                if (newCount !== 0) {
                    let items = [];
                    for (let number = 1; number <= newCount; number++) {
                        items.push(number);
                    }
                    setCount(items)
                } else {
                    setCount(1)
                }

            }
        })
    }

    const getPlanData = (id) => {
        Api.GetApiAdmin(`administrative/invoices/${id}`).then(res => {
            console.log("plan res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    if (res.data.data?.items && res.data.data.items.length > 0) {
                        let resData = []
                        res.data.data.items.map((opt) => {
                            if (opt?.gross_amount == 117900) {
                                resData.push({ ...opt, 'subtotal': 999 })
                            } else {
                                resData.push({ ...opt, 'subtotal': 1799 })
                            }
                        })

                        setplanData(resData)
                    }
                }
                setshow(true)
            }
        })
    }

    const handleDateChange = (e) => {
        setDate(e)
    }
    const handleDateChange2 = (e) => {
        setExpDate(e)
    }

    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const handleAddresschange = (e) => {
        setValue({
            ...value,
            address: {
                ...value.address,
                [e.target.name]: e.target.value
            }
        })
    }

    console.log("res", userData)

    // const updateStatus = () => {
    //     Api.PutApiNetwork(`domain-verification/updateStatus/${modalData.domain_verification_id}`).then(res => {
    //         if (res.error === true) {
    //             Api.Alert(res.response.data.error, "error")
    //         } else {
    //             Api.Alert(res.data.data.message, "success")
    //             getList(activeTab)
    //             changeTab()
    //         }
    //     })

    // }

    const openModal = (type, data, size) => {
        setmodalType(type)
        setmodalSize(size)
        setmodalData(data)
        if(type == "view"){
            setExpDate(new Date(data?.expired_at))
        }
        if (type == "plan" && data?.storeId && data?.StoreSubscriptions.length > 0) {
            getPlanData(data?.storeId)
        } else {
            setshow(true)
        }
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    //Search change
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("search", e.target.value)
        }
    }

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== "") {
                console.log("search:", search);
                setParams({ ...params, 'page': 1, 'search': search })
                setparam("search", search)
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);



    const maxVisiblePages = 5;

    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                    number <= params.page + Math.floor(maxVisiblePages / 2))
            ) {
                pageItems.push(
                    <Pagination.Item
                        key={number}
                        active={number === (params.page || searchParams.get("page"))}
                        onClick={() => pageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                (number === totalPages - 1 &&
                    params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
            ) {
                // Display ellipsis for the second and second-to-last pages
                pageItems.push(<Pagination.Ellipsis key={number} />);
            }
        }

        return pageItems;
    };
    const prevPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: Number(params.page) - 1 })
    }
    const nextPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) + 1)
        setParams({ ...params, page: Number(params.page) + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setParams({ ...params, page: number })
            setparam("page", number)
            // setloading(true)
        }
    }

    const handleChange = (e) => {
        setsubscriptionId(e.target.value)
    }

    const submitSubscription = () => {
        if (subscriptionId == "") {
            setError(true)
        } else {
            setError(false)
            let body = {
                "storeId": modalData?.storeId,
                "subscriptionId": subscriptionId
            }
            Api.PutApiAdmin('administrative/updateSubscription', body).then(res => {
                console.log("updateSubscription res", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        Api.Alert(res.data.data, "success")
                        getData(`/${urlParam.id || ""}`, location.search || "?page=1", "close")
                    }
                }
            })
        }
    }

    const updateTrial = () => {
        if (date !== "") {
            let body = {
                date: date
            }
            Api.PutApiAdmin(`administrative/updateTrialPeriod/${modalData?.storeId}`, body).then(res => {
                console.log("updateTrialPeriod res", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        Api.Alert(res.data.data, "success")
                        getData(`/${urlParam.id || ""}`, location.search || "?page=1", "")
                        setShowDatepicker(false)
                    }
                }
            })
        }
    }
    const updateExpire = () => {
        if (ExpDate !== "") {
            let body = {
                date: ExpDate
            }
            Api.PutApiAdmin(`administrative/updateStoreExpire/${modalData?.storeId}`, body).then(res => {
                console.log("updateStoreExpire res", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        Api.Alert(res.data.data, "success")
                        getData(`/${urlParam.id || ""}`, location.search || "?page=1", "")
                        setShowDatepicker2(false)
                    }
                }
            })
        }
    }

    const updateStore = () => {
        if (value?.store_name == "" || value?.address?.first_name == "" || value?.address?.address == "" || value?.address?.city == "") {
            setError(true)
        } else {
            setError(false)
            let body = {
                ...value
            }
            Api.PutApiAdmin(`administrative/updateStoreDetails/${modalData?.storeId}`, body).then(res => {
                console.log("updateStoreDetails res", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        Api.Alert(res.data.data, "success")
                        getData(`/${urlParam.id || ""}`, location.search || "?page=1", "close")
                    }
                }
            })
        }
    }


    const downloadInvoice = (data, i) => {
        var l = {
            orientation: 'p',
            unit: 'pt',
            format: 'a4',
            compress: true,
            autoSize: true,
            printHeaders: true
        }
        const doc = new jsPDF(l, '', '', '')
        const htmlToConvert = ReactDOMServer.renderToString(<DownloadInvoice data={data} />)
        // setallData(htmlToConvert)
        doc.html(htmlToConvert, {
            callback: function (doc) {
                doc.save(`Invoice_${moment(data?.billing_start * 1000).format("DDMMYY")}00${i + 1}.pdf`);
            }
        });
    }

    const handleCheckbox = (e, data) => {
        let arr = []
        userData.map((opt) => {
            if (opt.storeId == data?.storeId) {
                arr.push({ ...opt, checked: e.target.checked })
            } else {
                arr.push({ ...opt })
            }
        })
        setuserData(arr)
    }

    const deleteStore = () => {
        setmodalType("delete")
        setmodalSize("md")
        setshow(true)
    }

    const confirmDelete = () => {
        let selectedStore = []
        userData.map((x) => {
            if (x.checked) {
                selectedStore.push(x.storeId)
            }
        })
        let body = {
            store_id: selectedStore
        }
        Api.PostApiAdmin('administrative/delete', body).then(res => {
            console.log("delete res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    Api.Alert(res.data.data, "success")
                    getData(`/${urlParam.id || ""}`, location.search || "?page=1", "close")
                }
            }
        })
        console.log("selectedStore: ", selectedStore)
    }

    const cancelExpire = () =>{
        setShowDatepicker2(false)
        // setExpDate(new Date(modalData?.expired_at))
    }

    useEffect(() => {
        searchParams.set("page", params.page)
        searchParams.set("limit", limit)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        // if (location.search !== "") {
        getData(`/${urlParam.id || ""}`, location.search || "?page=1")
        // }
    }, [limit, params, props.type])
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    console.log("modalData: ", modalData)
    console.log("value: ", value)
    console.log("planData: ", planData)
    console.log("userData: ", userData)
    console.log("ExpDate: ", ExpDate)

    return (
        <>

            <div className='loginContainer justify-content-start py-3 networkPage'>
                <div className="container">
                    <div className="metworkHeader ">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="loginLogo">
                            <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                            </div>
                            <div className='admin_header'>
                                <NavLink to="/admin/users" className="btn btn-text-primary border-0 p-0 me-4">Users</NavLink>
                                <NavLink to="/admin/stores" className="btn btn-text-primary border-0 p-0 me-4">Stores</NavLink>
                                <NavLink to="/admin/themes" className="btn btn-text-primary border-0 p-0 me-4">Themes</NavLink>
                                <NavLink to="/admin/offers" className="btn btn-text-primary border-0 p-0 me-4">Offers</NavLink>
                                <button className="btn btn-outline-danger btn-sm" onClick={logout}>Logout</button>
                            </div>
                        </div>
                    </div>
                    <div className="  ">
                        <div className="row d-flex justify-content-between">
                            <div className='col-md-4'>
                                {props.type !== "user" ?
                                    <h4 className='mb-3'>Stores ({totalCount})</h4>
                                    :
                                    <button onClick={() => navigate(-1)} className="btn btn-text-success border-0 p-0 d-flex align-items-center mb-3">
                                        <FontAwesomeIcon icon={faArrowLeft} /> <h4 className='ms-2 mb-0'> Back to list</h4>
                                    </button>
                                }
                            </div>
                            <div className='col-md-4'>
                                <div className='search_main'>
                                    <div className='form-group mb-3'>
                                        <input placeholder='Search here...' type="search" className='form-control small' value={search} name="first_name" onChange={handleSearchChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card p-0'>
                            {props.type == "user" ?
                                <h5 className='p-3 mb-0'><strong>Stores ({totalCount})</strong></h5> : null
                            }
                            {userData && userData.length > 0 ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>
                                                {userData.filter((x) => x.checked).length ?
                                                    <button className='btn btn-sm btn-danger ms-3' onClick={deleteStore}>Delete</button>
                                                    :
                                                    "Store ID"
                                                }
                                            </th>
                                            <th>Store</th>
                                            <th>Domain</th>
                                            {/* <th>Address</th> */}
                                            <th>Created date</th>
                                            <th>Plan</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {userData.map((data, index) => {
                                            let updInd = index + 1
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {data?.store_email !== process.env.REACT_APP_THEME_EMAIL ?
                                                            <input type='checkbox' id={updInd} onChange={(e) => handleCheckbox(e, data)} checked={data.checked} />
                                                            : null
                                                        }
                                                    </td>
                                                    <td>
                                                        {(params.page - 1) * 20 + updInd}
                                                    </td>
                                                    <td>
                                                        <h6>{data?.storeId}</h6>
                                                        <h6 className='mb-0'><Badge bg={data.status == "active" ? "success" : "dark"} className='text-capitalize'>{data?.status}</Badge></h6>
                                                    </td>
                                                    <td>
                                                        {data?.store_name}<br />
                                                        <span style={{ opacity: 0.7 }}>{data?.store_email}</span>
                                                    </td>
                                                    <td>
                                                        Managed: <a href={data?.domain.startsWith("http") ? data?.domain : `https://${data?.domain}`} target='_blank' className='btn btn-text-primary btn-sm p-0'>{data?.domain}</a> <br />
                                                        {data?.liveDomain ?
                                                            <>
                                                                Primary: <a href={data?.liveDomain.startsWith("http") ? data?.liveDomain : `https://${data?.liveDomain}`} target='_blank' className='btn btn-text-danger btn-sm p-0'>{data?.liveDomain}</a>
                                                            </> : null
                                                        }

                                                    </td>
                                                    {/* <td>{data?.address}</td> */}
                                                    <td>{moment(data?.created_at).format("lll")}</td>
                                                    <td>
                                                        {/* <button className='btn btn-success btn-sm me-3 px-3' onClick={() => openModal("plan", data, "md")}>View</button> */}
                                                        <p className='mb-0' style={{ whiteSpace: "nowrap" }}>
                                                            <strong>{data?.plan || "No active plan"}</strong>
                                                            <button className='btn btn-text-primary p-0 border-0 ms-2' onClick={() => openModal("plan", data)}>
                                                                <FontAwesomeIcon icon={faPencilAlt} fontSize={12} />
                                                            </button>
                                                        </p>
                                                        {data?.is_trail ?
                                                            <h6>
                                                                <Badge bg="success" className='me-2 mb-1 text-capitalize'>{data?.planDetail?.status}</Badge>
                                                                <Badge bg="danger">Trial</Badge>
                                                            </h6>
                                                            :
                                                            <h6>
                                                                <Badge bg="success" className='text-capitalize'>{data?.planDetail?.status}</Badge>
                                                            </h6>
                                                        }
                                                    </td>
                                                    <td style={{ width: 200 }}>
                                                        <button className='btn btn-success btn-sm me-3 px-3' onClick={() => openModal("view", data, "md")}>View</button>
                                                        <button className='btn btn-primary btn-sm px-3' onClick={() => openModal("edit", data, "lg")}>Edit</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table> :
                                <div className='text-center py-5'>
                                    <h2>No data found</h2>
                                </div>
                            }
                        </div>
                        {/* {userData && userData.length === 0 ? null : */}
                        <Pagination>
                            <Pagination.First onClick={prevPage} disabled={params.page === 1} />
                            {getPageItems()}
                            <Pagination.Last
                                onClick={nextPage}
                                disabled={params.page === count.length}
                            // disabled={params.page === count}
                            />
                        </Pagination>
                        {/* } */}

                    </div>
                </div>
            </div >

            <Modal show={show} onHide={handleClose} size={modalSize}>
                <Modal.Header closeButton>
                    {!_.isEmpty(modalData) ?
                        <div className='d-flex justify-content-between align-items-center'>
                            <h6 className='mb-0'>{modalData?.store_name} ({modalData?.storeId})
                                <a href={modalData?.domain.startsWith("http") ? modalData?.domain : `https://${modalData?.domain}`} target='_blank' className='btn btn-text-primary btn-sm'><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></h6>
                            {modalType == "view" ?
                                <button className='btn btn-text-primary border-0 p-0' onClick={() => openModal("edit", modalData, "lg")}><FontAwesomeIcon icon={faPencilAlt} fontSize={14} /></button>
                                : null
                            }
                        </div>
                        : null
                    }
                </Modal.Header>
                <Modal.Body>
                    {modalType == "plan" ?
                        <div className=''>
                            {!_.isEmpty(modalData) ?
                                <>
                                    {modalData?.is_trail ?
                                        <div>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item className='px-0'>
                                                    <h5 className='d-inline-block text-capitalize mb-0'><Badge bg="danger">Trial</Badge></h5>
                                                </ListGroup.Item>
                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Trial end date:</strong>
                                                    {showDatepicker ?
                                                        <div className='d-inline-flex'>
                                                            <DatePicker
                                                                selected={date || new Date(modalData?.expired_at) || ""}
                                                                className={`form-control small datepicker`}
                                                                onChange={handleDateChange}
                                                                placeholderText="Select date"
                                                                dateFormat="dd/MM/yyyy"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                minDate={addDays(new Date(), 1)}
                                                                maxDate={addDays(new Date(modalData?.expired_at), 30)}
                                                                peekNextMonth
                                                            />
                                                            <button className='btn btn-primary btn-sm ms-2' onClick={updateTrial}>Update</button>
                                                            <button className='btn btn-outline-danger btn-sm ms-2' onClick={() => setShowDatepicker(false)}>Cancel</button>
                                                        </div>
                                                        :
                                                        <div className='d-inline-flex'>
                                                            {moment(date || modalData?.expired_at).format("ll")}
                                                            <button className='btn btn-text-primary p-0 border-0 ms-3' onClick={() => setShowDatepicker(true)}><FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: "12px" }} /></button>
                                                        </div>
                                                    }
                                                </ListGroup.Item>
                                                <ListGroup.Item className='px-0'></ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                        : null
                                    }
                                    {planData.length > 0 ?
                                        <Accordion>
                                            {planData.map((data, i) => {
                                                return (
                                                    <Accordion.Item eventKey={i} key={i}>
                                                        <Accordion.Header>
                                                            <p className='mb-0 d-flex justify-content-between w-100 pe-3'>
                                                                <small><strong>{data?.subscription_id}</strong></small>
                                                                {data?.billing_start && data?.billing_end ?
                                                                    <small>{moment.unix(data?.billing_start).format("ll")} - {moment.unix(data?.billing_end).format("ll")}</small>
                                                                    : null
                                                                }
                                                            </p>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <ListGroup variant="flush">
                                                                {data?.line_items && data.line_items.length > 0 ?
                                                                    <ListGroup.Item className='px-0'><strong className='store_detail_label'>Plan:</strong> <strong>{data.line_items[0]?.name}</strong></ListGroup.Item> :
                                                                    null
                                                                }
                                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Invoice ID:</strong> {data?.id}</ListGroup.Item>
                                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Payment ID:</strong> {data?.payment_id}</ListGroup.Item>
                                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Subscription ID:</strong> {data?.subscription_id}</ListGroup.Item>
                                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Status:</strong>
                                                                    <h6 className='d-inline-block text-capitalize mb-0'><Badge bg="secondary">{data?.status}</Badge></h6>
                                                                </ListGroup.Item>
                                                                {data?.billing_start || data?.issued_at ?
                                                                    <ListGroup.Item className='px-0'><strong className='store_detail_label'>Start date:</strong> {moment.unix(data?.billing_start || data?.issued_at).format("lll")}</ListGroup.Item>
                                                                    : null
                                                                }
                                                                {data?.billing_end ?
                                                                    <ListGroup.Item className='px-0'><strong className='store_detail_label'>Next renewal:</strong> {moment.unix(data?.billing_end).format("lll")}</ListGroup.Item>
                                                                    : null
                                                                }
                                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Amount:</strong> {Api.CURRENCY}{((data?.amount_paid) / 100).toFixed(2)}</ListGroup.Item>
                                                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Currency:</strong> {data?.currency}</ListGroup.Item>
                                                                {data?.short_url ?
                                                                    <ListGroup.Item className='px-0 text-end'>
                                                                        <button className='btn btn-success btn-sm' onClick={() => downloadInvoice(data, i)}>Download invoice</button>
                                                                        {/* <a href={data?.short_url} target='_blank' className='btn btn-success btn-sm'>Download invoice</a> */}
                                                                    </ListGroup.Item>
                                                                    : null
                                                                }
                                                                {/* <DownloadInvoice data={data} /> */}
                                                            </ListGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            })}
                                        </Accordion>
                                        :
                                        modalData?.StoreSubscriptions.length > 0 ?
                                            <ListGroup>
                                                <ListGroup.Item><strong className='store_detail_label'>Status:</strong>
                                                    <h6 className='d-inline-block text-capitalize mb-0'><Badge bg="danger">{modalData?.StoreSubscriptions[0]?.status}</Badge></h6>
                                                </ListGroup.Item>
                                                {modalData?.StoreSubscriptions[0]?.start_at ?
                                                    <ListGroup.Item><strong className='store_detail_label'>Start date:</strong> {moment.unix(modalData?.StoreSubscriptions[0]?.start_at).format("lll")}</ListGroup.Item>
                                                    : null
                                                }
                                                {/* {modalData?.StoreSubscriptions[0]?.link ?
                                                    <ListGroup.Item className='text-end'>
                                                        <button className='btn btn-success btn-sm' onClick={() => downloadInvoice(data, i)}>Download invoice</button>
                                                    </ListGroup.Item>
                                                    : null
                                                } */}
                                            </ListGroup>
                                            : null
                                    }

                                </>
                                :
                                <div className='p-3'>
                                    <h6>Invoice not found</h6>
                                </div>
                            }
                            {modalData?.StoreSubscriptions.length == 0 || modalData?.StoreSubscriptions[0]?.status == "created" || modalData?.StoreSubscriptions[0]?.status == "cancelled" ?
                                <div>
                                    <hr />
                                    {manualSub ?
                                        <>
                                            <div className='mt-3'>
                                                <h6>Manual subscription</h6>
                                                <div className='login_outer' >
                                                    <div className='form-group mb-3'>
                                                        <div>
                                                            <input placeholder='Enter subscription ID' type="text" className='form-control' value={subscriptionId} name="subscriptionId" onChange={handleChange} />
                                                            {errors && subscriptionId == "" ?
                                                                <p className='mb-0 error'><small>Please enter subscription ID</small></p>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <button className='btn btn-primary btn-sm me-2' onClick={submitSubscription}>Submit</button>
                                                    <button className='btn btn-outline-danger btn-sm' onClick={() => setManualSub(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <button className='btn btn-primary btn-sm me-3' onClick={() => setManualSub(true)}>Add manual subscription</button>
                                    }
                                </div>
                                : null
                            }
                        </div>
                        : null
                    }

                    {modalType == "view" ?
                        <div className=''>
                            <ListGroup variant="flush">
                                <ListGroup.Item className='pt-0 px-0'>
                                    {modalData?.status ?
                                        <h5 className='d-inline-block text-capitalize mb-0 me-2'><Badge bg={modalData.status == "active" ? "success" : "dark"} className='text-capitalize'>{modalData?.status}</Badge></h5>
                                        : null
                                    }
                                    {modalData?.is_trail ?
                                        <h5 className='d-inline-block text-capitalize mb-0'><Badge bg="danger">Trial</Badge></h5>
                                        : null
                                    }
                                </ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Expire date:</strong>
                                    {showDatepicker2 ?
                                        <div className='d-inline-flex'>
                                            <DatePicker
                                                selected={ExpDate || ""}
                                                className={`form-control small datepicker`}
                                                onChange={handleDateChange2}
                                                placeholderText="Select date"
                                                dateFormat="dd/MM/yyyy"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                minDate={new Date(ExpDate)}
                                                peekNextMonth
                                            />
                                            <button className='btn btn-primary btn-sm ms-2' onClick={updateExpire}>Update</button>
                                            <button className='btn btn-outline-danger btn-sm ms-2' onClick={cancelExpire}>Cancel</button>
                                        </div>
                                        :
                                        <div className='d-inline-flex'>
                                            {moment(ExpDate).format("lll")}
                                            <button className='btn btn-text-primary p-0 border-0 ms-3' onClick={() => setShowDatepicker2(true)}><FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: "12px" }} /></button>
                                        </div>
                                    }
                                </ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Store email:</strong> {modalData?.store_email || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Sender email:</strong> {modalData?.sender_email || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Phone number:</strong> {modalData?.phone_number || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Managed domain:</strong> {modalData?.domain || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Live domain:</strong> {modalData?.liveDomain || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Company name:</strong> {modalData?.company_name || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Industry:</strong> {modalData?.industry || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Revenue:</strong> {modalData?.revenue || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Order prefix:</strong> {modalData?.order_prefix || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Order suffix:</strong> {modalData?.order_suffix || "-"}</ListGroup.Item>
                                <ListGroup.Item className='px-0'><strong className='store_detail_label'>Currency:</strong> {modalData?.currency || "-"}</ListGroup.Item>
                            </ListGroup>
                        </div>
                        : null
                    }


                    {modalType == "edit" ?
                        <div className=''>
                            <h6 className='mb-3'>Edit store detail</h6>
                            <div className='login_outer'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label>Store name*</label>
                                            <div>
                                                <input placeholder='Store name' type="text" className='form-control' defaultValue={value?.store_name || modalData?.store_name} name="store_name" onChange={handleOnchange} />
                                                {errors && value?.store_name == "" ?
                                                    <p className='mb-0 error'><small>Please enter store name</small></p>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label>Store email*</label>
                                            <div>
                                                <input placeholder='Store email' type="text" className='form-control' defaultValue={value?.store_email || modalData?.store_email} name="store_email" onChange={handleOnchange} />
                                                {errors && value?.store_email == "" ?
                                                    <p className='mb-0 error'><small>Please enter store email</small></p>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label>Sender email</label>
                                            <div>
                                                <input placeholder='Sender email' type="text" className='form-control' defaultValue={value?.sender_email || modalData?.sender_email} name="sender_email" onChange={handleOnchange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Phone number</label>
                                        <div className='form-group mb-3'>
                                            <div className='customPhone'>
                                                <PhoneInput
                                                    enableSearch={true}
                                                    disableAreaCodes={false}
                                                    country={'in'}
                                                    value={value?.phone_number || modalData?.phone_number}
                                                    // onChange={phone => setPhone({ phone })}
                                                    inputExtrastate={{ name: 'mobile', required: true }}
                                                    inputProps={{ maxLength: '17' }}
                                                    onChange={(e, x) => handleNumberChange(e, x)}
                                                    isValid={(value, data) => {
                                                        if (
                                                            value
                                                                .replace(/[^0-9]+/g, "")
                                                                .slice(data && (data.dialCode.length)).length < 10 &&
                                                            value
                                                                .replace(/[^0-9]+/g, "")
                                                                .slice(data && (data.dialCode.length)).length > 0
                                                        ) {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    }}
                                                    placeholder=""
                                                    countryCodeEditable={false}
                                                    defaultMask=".. ... ...."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <label>Managed domain</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Managed domain' type="text" className='form-control' defaultValue={value?.domain || modalData?.domain} name="domain" onChange={handleOnchange}  />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-6'>
                                        <label>Company</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Company' type="text" className='form-control' defaultValue={value?.company_name || modalData?.company_name} name="company_name" onChange={handleOnchange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Industry</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                {/* <input placeholder='Industry' type="text" className='form-control' defaultValue={value?.industry || modalData?.industry} name="industry" onChange={handleOnchange} /> */}

                                                <div className='form-select-outer'>
                                                    <select name="industry" className='form-control w-100' onChange={handleOnchange} defaultValue={value?.industry || modalData?.industry}>
                                                        <option value="" disabled selected>Industry</option>
                                                        {storeType.map((opt, i) => {
                                                            return (
                                                                <option value={opt?.value} key={i}>{opt?.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Order prefix</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Order prefix' type="text" className='form-control' defaultValue={value?.order_prefix || modalData?.order_prefix} name="order_prefix" onChange={handleOnchange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Order suffix</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Order suffix' type="text" className='form-control' defaultValue={value?.order_suffix || modalData?.order_suffix} name="order_suffix" onChange={handleOnchange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h5>Address</h5>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>First name*</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='First name' type="text" className='form-control' defaultValue={value?.address?.first_name || modalData?.StoreAddress?.first_name} name="first_name" onChange={handleAddresschange} />
                                                {errors && value?.address?.first_name == "" ?
                                                    <p className='mb-0 error'><small>Please enter first name</small></p>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Last name</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Last name' type="text" className='form-control' defaultValue={value?.address?.last_name || modalData?.StoreAddress?.last_name} name="last_name" onChange={handleAddresschange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Address line 1*</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Address line 1*' type="text" className='form-control' defaultValue={value?.address?.address || modalData?.StoreAddress?.address} name="address" onChange={handleAddresschange} />
                                                {errors && value?.address?.address == "" ?
                                                    <p className='mb-0 error'><small>Please enter address line 1</small></p>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Address line 2</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='Address line 2' type="text" className='form-control' defaultValue={value?.address?.apartment || modalData?.StoreAddress?.apartment} name="apartment" onChange={handleAddresschange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <label>City*</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <input placeholder='City' type="text" className='form-control' defaultValue={value?.address?.city || modalData?.StoreAddress?.city} name="city" onChange={handleAddresschange} />
                                                {errors && value?.address?.city == "" ?
                                                    <p className='mb-0 error'><small>Please enter city</small></p>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <label>State</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                <div className='form-select-outer'>
                                                    <select name="state" className='form-control w-100' defaultValue={value?.address?.state || modalData?.StoreAddress?.state} onChange={handleAddresschange}>
                                                        <option value={""} selected>Select state</option>
                                                        {states.map((opt) => {
                                                            return (
                                                                <option value={opt?.name} key={opt?.code}>{opt?.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <label>Pincode</label>
                                        <div className='form-group mb-3'>
                                            <div>
                                                {/* <input placeholder='Pincode' type="text" className='form-control' defaultValue={value?.address?.pincode || modalData?.StoreAddress?.pincode} name="pincode" onChange={handleAddresschange} /> */}
                                                <CurrencyFormat format="######" mask="" name="pincode" value={value?.address?.pincode || modalData?.StoreAddress?.pincode} className="form-control" placeholder="Pincode" onChange={handleAddresschange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <button className='btn btn-primary btn-sm me-2' onClick={updateStore}>Update</button>
                                <button className='btn btn-outline-danger btn-sm' onClick={handleClose}>Cancel</button>
                            </div>
                        </div>
                        : null
                    }

                    {modalType == "delete" ?
                        <div>
                            <div className="text-center mb-4">
                                <h4> Are you sure?</h4>
                                <h5>You want to delete selected stores.</h5>
                            </div>
                            <div className="text-center mt-2">
                                <button className="btn btn-success btn-sm me-3" onClick={confirmDelete} > Confirm</button>
                                <button className="btn btn-outline-danger btn-sm" onClick={handleClose}> Cancel</button>
                            </div>
                        </div>
                        : null
                    }


                </Modal.Body>
            </Modal >
        </>
    )
}
